import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("DTab", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      defaultIndex: _vm.defaultIndex,
      titles: _vm.titles,
      handleClick: _vm.handleClick,
      showNumb: true
    }
  }), _c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey,
      disabledDate: _vm.disabledDate,
      onSelectChange: _vm.handleProvinceChange
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:add"],
      expression: "['commodity:btn:add']"
    }],
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleChangeStatus("", 3);
      }
    }
  }, [_vm._v("新增")]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:shevelsup"],
      expression: "['commodity:btn:shevelsup']"
    }],
    staticStyle: {
      margin: "10px",
      border: "0px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleShelves(0);
      }
    }
  }, [_vm._v("批量上架")]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:shevelsdown"],
      expression: "['commodity:btn:shevelsdown']"
    }],
    staticStyle: {
      border: "0px",
      background: "#f5f5f5"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleShelves(1);
      }
    }
  }, [_vm._v("批量下架")]), _c("a-table", {
    staticClass: "commodity",
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      scroll: {
        x: 2000
      },
      rowClassName: _vm.setRowClassName,
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectdChange,
        fixed: true
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "imgurls" ? _c("span", [record.imgurls ? _c("img", {
            staticClass: "product_img",
            attrs: {
              src: _vm.splitImg(record)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record));
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : key == "managementid" ? _c("span", [_c("span", [_vm._v(_vm._s(record.id))])]) : key == "managementcategory" ? _c("span", [_c("span", [_vm._v(_vm._s(record.parentcategoryname + "-" + record.categoryname))])]) : key == "name" ? _c("span", [_c("span", [_vm._v(_vm._s(record.name.slice(0, 15)) + _vm._s(record.name.length > 15 ? "..." : ""))])]) : key == "status" ? _c("span", [_c("span", [_vm._v(_vm._s(record.status === 0 ? "否" : "是"))])]) : key == "count" ? _c("span", [_c("span", {
            staticStyle: {
              color: "#3270f5"
            },
            on: {
              click: function click($event) {
                return _vm.gotobusiness(record);
              }
            }
          }, [_vm._v(_vm._s(record.count))])]) : key == "operation" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["recommendgoods:btn:recommend"],
              expression: "['recommendgoods:btn:recommend']"
            }],
            on: {
              click: function click($event) {
                return _vm.recommendgoods(record);
              }
            }
          }, [_vm._v("推荐商品")]), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["commodity:btn:detail"],
              expression: "['commodity:btn:detail']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleChangeStatus(record.id, 1);
              }
            }
          }, [_vm._v("查看")])], 1), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["commodity:btn:edit"],
              expression: "['commodity:btn:edit']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleChangeStatus(record.id, 2);
              }
            }
          }, [_vm._v("修改")])], 1), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["commodity:btn:copy"],
              expression: "['commodity:btn:copy']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleChangeStatus(record.id, 4);
              }
            }
          }, [_vm._v("复制")])], 1), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["commodity:btn:off"],
              expression: "['commodity:btn:off']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handlestatus(record.id, record.status);
              }
            }
          }, [_vm._v(_vm._s(record.status == 0 ? "上架" : "下架"))])], 1), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["commodity:btn:delete"],
              expression: "['commodity:btn:delete']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleDelete(record.id);
              }
            }
          }, [_vm._v("删除")])], 1)]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      title: _vm.title,
      loading: _vm.loading,
      isDelete: _vm.isDelete,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleSubmit
    }
  }), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };